.product_salmon {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.product_salmon img {
    width: 268px;
    height: 461px;
    border-radius: 5%;
}

.product_salmon:hover img {
    transform: scale(1.1);
    cursor: pointer;
}

.product_salmon img {
    transition: transform 0.25s;
}

@media screen and (max-width: 430px){
    .product_salmon img {
        width: 86px;
        height: 85px;

        object-fit: contain;
    }
}
