.contacts__info {
    display: flex;
    justify-content: space-around;

    background-color: #40649E;

    height: 573px;
    width: 100%;

    color: #FFF;

    padding: 81px 127px;
}

.contacts__info_map {
    display: flex;
    align-items: center;
}

.contacts__info_address {
    width: 250px;
}

.contacts__info_address p {
    font-size: 25px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0.085px;
}

.contacts__info_address h2 {
    color: #FFF;
    font-family: Rubik;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: 70px;
}

@media screen and (max-width: 430px) {
    .contacts__info {
        padding: 10px 0 0 29px;
        height: 203px;

        justify-content: normal;

        margin-bottom: 79px;
    }

    .contacts__info_map img {
        width: 279px;
        height: 142px;
    }

    .contacts__info_address {
        width: 54px;
    }

    .contacts__info_address p {
        font-size: 8px;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.02px;
    }

    .contacts__info_address h2 {
        font-size: 10px;
    }
}