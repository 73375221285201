.product__content_wrap {
    background-image: url('../../files/photos/products/backdrounds/Japanese_Wave_Pattern 1.png');
    background-size: contain;

    padding-bottom: 60px;
}
.product__content_wrap_dekstop {}

.product__content_wrap_mobile {
    display: none;
}

.product__content {
    box-sizing: border-box;

    display: flex;
    align-items: self-start;

    column-gap: 79px;

    min-height: 700px;
}

.product__content__information {
    width: 729px;
    padding-top: 40px;
    line-height: 25px;
    box-sizing: border-box;
    margin-top: 41px;
}

.product__content__information,
.product__content__information_second {
    background-color: rgba(255, 255, 255, 0.5);
}

.product__content__information_second {
    padding: 10px 0;
}

.product__content__information_description {
    white-space: pre-line;
    padding: 15px 59px 0 59px;

    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.product__content__information_sub_description {
    color: #40649E;
    font-size: 20px;
    font-weight: 700;
}

.product__content_title {
    color: #40649E;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 60px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 88px;

    padding-right: 40px;
}

.product__content_circle,
.product__content_circle_11 {
    background-color: #FFF2C4;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
}

.product__content_circle {
    height: 567px;
    width: 567px;

    margin-top: 119px;
}

.product__content_circle_11 {
    height: 567px;
    width: 567px;

    margin-top: 219px;
}

.product__content_circle img {
    max-width: 440px;
}
.product__content_circle_11 img {
    max-width: 752px;
}

.product__content_circle img {
    transform: rotate(-65deg);
}

.product__content_circle_11 img {
    width: 637.536px;
    height: 198.82px;
    transform: rotate(-66.219deg);

    position: relative;
    bottom: 65px;
}

@media screen and (min-width: 376px) and (max-width: 430px) {
    .product__content_wrap_v2 {
        background-image: url('../../files/photos/products/backdrounds/Japanese_Wave_Pattern 1.png');
        background-size: contain;
        padding-bottom: 0;

        height: 70vh;
        overflow-y: hidden;
    }

    .product__content__information_sub_description,
    .product__content_title {
        font-size: 8px;
    }

    .product__content_wrap_dekstop {
        display: none;
    }

    .product__content_wrap_mobile {
        display: block;
    }

    .product__content_title {
        padding: 20px 0 0 22px;

        line-height: 15px;
    }

    .product__content_circle,
    .product__content_circle_11 {
        margin-top: 10px;

        width: 168.474px;
        height: 168.474px;
    }

    .product__content_circle_11 img {
        position: relative;
        bottom: 0;
    }

    .product__content__information_description {
        font-size: 7px;
        font-weight: 400;
        line-height: normal;

        padding: 15px 22px 15px 22px;
    }

    .product__content__information_description_1 {
        font-size: 7px;
        font-weight: 400;
        line-height: normal;

        padding: 15px 0 0 22px;

        height: 176px;
    }

    .product__content__information_description_2 {
        font-size: 7px;
        font-weight: 400;
        line-height: normal;

        padding: 15px 22px 0 22px;
    }

    .product__content {
        min-height: 228px;
        column-gap: 10px;

        background-size: 338px;

        padding: 24px 0 14px 0;
    }

    .product__content img {
        width: 148.29px;
        height: 146.191px;

        object-fit: scale-down;
    }

    .product__content__information {
        margin: 0;

        padding-top: 2px;

        width: 184px;
    }

    .test {
        position: relative;
        background-color: #FFFFFF;

        overflow-y: hidden;
        height: 100px;
    }

    .product__content_circle_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 50%;
    }
}

@media screen and (min-width: 321px) and (max-width: 375px) {
    .product__content_wrap_v2 {
        background-image: url('../../files/photos/products/backdrounds/Japanese_Wave_Pattern 1.png');
        background-size: contain;
        padding-bottom: 0;

        height: 70vh;
        overflow-y: hidden;
    }

    .product__content__information_sub_description,
    .product__content_title {
        font-size: 8px;
    }

    .product__content_wrap_dekstop {
        display: none;
    }

    .product__content_wrap_mobile {
        display: block;
    }

    .product__content_title {
        padding: 20px 0 0 22px;

        line-height: 15px;
    }

    .product__content_circle,
    .product__content_circle_11 {
        margin-top: 10px;

        width: 155.474px;
        height: 155.474px;
    }

    .product__content_circle_11 img {
        position: relative;
        bottom: 0;
    }

    .product__content__information_description {
        font-size: 7px;
        font-weight: 400;
        line-height: normal;

        padding: 15px 22px 15px 22px;
    }

    .product__content__information_description_1 {
        font-size: 7px;
        font-weight: 400;
        line-height: normal;

        padding: 15px 0 0 22px;

        height: 176px;
    }

    .product__content__information_description_2 {
        font-size: 7px;
        font-weight: 400;
        line-height: normal;

        padding: 15px 22px 0 22px;
    }

    .product__content {
        min-height: 228px;
        column-gap: 10px;

        background-size: 338px;

        padding: 24px 0 14px 0;
    }

    .product__content img {
        width: 130.29px;
        height: 130.191px;

        object-fit: scale-down;
    }

    .product__content__information {
        margin: 0;

        padding-top: 2px;

        width: 184px;
    }

    .test {
        position: relative;
        background-color: #FFFFFF;

        overflow-y: hidden;
        height: 100px;
    }

    .product__content_circle_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        position: relative;

        left: -6px;
    }
}

@media screen and (max-width: 320px) {
    .product__content_wrap_v2 {
        background-image: url('../../files/photos/products/backdrounds/Japanese_Wave_Pattern 1.png');
        background-size: contain;

        padding-bottom: 60px;
    }
    .product__content__information_sub_description,
    .product__content_title {
        font-size: 8px;
    }

    .product__content_wrap_dekstop {
        display: none;
    }

    .product__content_wrap_mobile {
        display: block;
    }

    .product__content_title {
        padding: 20px 0 0 22px;

        line-height: 15px;
    }

    .product__content_circle,
    .product__content_circle_11 {
        margin-top: 10px;

        width: 108.474px;
        height: 108.474px;
    }

    .product__content_circle_11 img {
        position: relative;
        bottom: 0;
    }

    .product__content__information_description {
        font-size: 7px;
        font-weight: 400;
        line-height: normal;

        padding: 15px 22px 15px 22px;
    }

    .product__content__information_description_1 {
        font-size: 7px;
        font-weight: 400;
        line-height: normal;

        padding: 15px 22px 0 22px;

        height: 176px;
    }

    .product__content__information_description_2 {
        font-size: 7px;
        font-weight: 400;
        line-height: normal;

        padding: 15px 22px 0 22px;
    }

    .product__content {
        min-height: 228px;
        column-gap: 10px;

        background-size: 338px;

        padding: 24px 0 14px 0;
    }

    .product__content img {
        width: 108.474px;
        height: 108.474px;

        object-fit: scale-down;
    }

    .product__content__information {
        margin: 0;

        padding-top: 2px;

        width: 184px;
    }

    .test {
        position: relative;
        background-color: #FFFFFF;

        overflow-y: hidden;
        min-height: 110px;
        height: 100%;
    }

    .product__content_circle_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 250px;
    }
}