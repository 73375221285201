.burger__menu {
    background-color: #C1BCBC17;
    padding: 0 10px;

    box-sizing: border-box;

    width: 80px;

    height: 80px;
}

.burger__menu_a {
    color: #656565;
    font-size: 6px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.5px;
    /*padding: 0;*/
}

.burger__menu,
.burger__menu_a,
li {
    margin: 0;
}
li{
    color: #656565;
    font-size: 6px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.5px;
}

.burger__menu_a:hover {
    color: rgba(101, 101, 101, 0.5);
}