#contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;

    padding-top: 53px;
    background-image: url("../../../src/files/photos/contacts/Japanese_Wave_Pattern.png");
}

#contacts > p {
    width: 200px;
}

.contacts__title {
    color: #40649E;
    text-align: center;
    font-family: Rubik;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 171.5%;
}

@media screen and (max-width: 430px) {
    #contacts {
        background-image: url("../../files/photos/contacts/Japanese_Wave_Pattern 4.png");

        padding-top: 25px;
    }

    .contacts__title {
        font-size: 16px;
        font-weight: 600;

        align-self: start;

        margin-left: 30px;
    }
}