.product__appetize {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product__appetize:hover img {
    transform: scale(1.1);
    cursor: pointer;
}

.product__appetize img {
    transition: transform 0.25s;
}


@media screen and (max-width: 430px){
    .product__appetize img {
        width: 150px;
        height: 85px;
    }
}


/*.product {*/
/*    transition: all 2s ease;*/
/*}*/
/*.product:hover {*/
/*    transition: all 0.1s ease; background-color: #8BE5ED; }*/

/*.product img:hover {*/
/*    cursor: pointer;*/
/*    opacity: 0.7;*/
/*}*/
