#prawn__seafood_products,
#prawn__salmon_products {}

.prawn__seafood_page_products {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.prawn__seafood_page_products h2 {
    color: #023384;
}

.prawn__seafood_page_products_hr_wrap {
    display: flex;
    justify-content: center;
    align-items: end;
    height: 120px;
    padding: 0;
    margin: 0;
}

.prawn__seafood_page_products_hr {
    width: 89%;
    padding: 0;
    margin: 0;
}

@media screen and (min-width: 320px) and (max-width: 430px) and (max-height: 750px) {
    .prawn__seafood_page_products {
        row-gap: 0;
    }

    .prawn__seafood_page_products_hr_wrap {
        height: 30px;
    }

    .prawn__seafood_page_products_wrap {
        height: calc(100vh * 2);
    }
}

@media screen and (min-width: 320px) and (max-width: 430px) and (min-height: 751px) and (max-height: 800px) {
    .prawn__seafood_page_products {
        row-gap: 0;
    }

    .prawn__seafood_page_products_hr_wrap {
        height: 30px;
    }

    .prawn__seafood_page_products_wrap {
        height: calc(100vh * 1.9);
    }
}

@media screen and (min-width: 320px) and (max-width: 430px) and (min-height: 801px)  and (max-height: 809px) {
    .prawn__seafood_page_products {
        row-gap: 0;
    }

    .prawn__seafood_page_products_hr_wrap {
        height: 30px;
    }

    .prawn__seafood_page_products_wrap {
        height: calc(100vh * 1.8);
    }
}

@media screen  and (min-width: 320px) and (max-width: 430px) and (min-height: 810px) and (max-height: 900px) {
    .prawn__seafood_page_products {
        row-gap: 0;
    }

    .prawn__seafood_page_products_hr_wrap {
        height: 30px;
    }

    .prawn__seafood_page_products_wrap {
        height: calc(100vh * 1.72);
    }
}

@media screen  and (min-width: 320px) and (max-width: 430px) and (min-height: 901px)  {
    .prawn__seafood_page_products {
        row-gap: 0;
    }

    .prawn__seafood_page_products_hr_wrap {
        height: 30px;
    }

    .prawn__seafood_page_products_wrap {
        height: calc(100vh * 1.6);
    }
}


@media screen and (max-width: 320px){
    .prawn__seafood_page_products {
        row-gap: 0;
    }

    .prawn__seafood_page_products_hr_wrap {
        height: 30px;
    }

    .prawn__seafood_page_products_wrap {
        height: calc(100vh * 3.3);
    }
}