.logo {
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
}

.logo__active,
.logo__leave {
    background-image: url("../../files/photos/water/water-effect.png");
    background-size: cover;

    position: absolute;
    bottom: -5px;
    left: -19%;

    height: 180%;
    width: 140%;

    border-radius: 50%;

    animation: ani 1.5s forwards;
}

.logo__leave {
    animation: ani_back 1.3s forwards;
}

@keyframes ani {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes ani_back {
    0% {opacity: 1;}
    100% {opacity: 0;}
}

@media screen and (max-width: 720px) {
    .logo__active,
    .logo__leave {
        display: none;
    }
}