.carousel {
    display: none;
}

.main__who_we_are {
    height: 796px;
    width: 100%;
    background-image: url("../../../files/photos/who-we-are/who-we-are.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    padding: 71px 0 0 138px;
}

.main__who_we_are_title {
    font-family: Rubik;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: 70px;

    color: #40649E;
}

.main__who_we_are_description {
    width: 567px;

    margin-top: 45px;
}

.main__who_we_are_description p {
    width: 100%;
    height: 100%;

    /*text-size-adjust: none;*/

    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.075px;
}

@media screen and (max-width: 1400px) {
    .carousel {
        display: block;
    }
}

@media screen and (min-width: 1320px) and (max-width: 1400px) {
    .main__who_we_are {
        padding: 71px 0 0 100px;
    }
}

@media screen and (min-width: 1275px) and (max-width: 1320px) {
    .main__who_we_are {
        padding: 71px 0 0 90px;
    }
}

@media screen and (min-width: 1255px) and (max-width: 1275px) {
    .main__who_we_are {
        padding: 71px 0 0 80px;
    }
}

@media screen and (min-width: 1245px) and (max-width: 1255px) {
    .main__who_we_are {
        padding: 71px 0 0 70px;
    }

    .main__who_we_are_description p {
        font-size: 28px;
    }
}

@media screen and (min-width: 1220px) and (max-width: 1245px) {
    .main__who_we_are {
        padding: 71px 0 0 60px;
    }

    .main__who_we_are_description p {
        font-size: 28px;
    }
}

@media screen and (min-width: 1199px) and (max-width: 1220px) {
    .main__who_we_are {
        padding: 71px 0 0 50px;
    }

    .main__who_we_are_description p {
        font-size: 26px;
    }
}

@media screen and (min-width: 1175px) and (max-width: 1199px) {
    .main__who_we_are {
        padding: 71px 0 0 50px;
    }

    .main__who_we_are_description p {
        font-size: 24px;
    }
}

@media screen and (min-width: 1160px) and (max-width: 1175px) {
    .main__who_we_are {
        padding: 71px 0 0 50px;
    }

    .main__who_we_are_description p {
        font-size: 22px;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1160px) {
    .main__who_we_are {
        padding: 71px 0 0 46px;
    }

    .main__who_we_are_description p {
        font-size: 20px;
    }
}

@media screen and (min-width: 1082px) and (max-width: 1100px) {
    .main__who_we_are {
        padding: 71px 0 0 35px;
    }

    .main__who_we_are_description p {
        font-size: 16px;
        line-height: 34px;
    }
}

@media screen and (min-width: 321px) and (max-width: 430px) {
    .main__who_we_are {
        height: 226px;

        padding: 0 30px 0 30px;

        background-image: none;

        object-fit: contain;
    }

    .main__who_we_are_description {
        margin: 0;

        width: 100%;

        display: flex;
        align-items: center;
        column-gap: 10px;
    }

    .main__who_we_are_title {
        font-size: 10px;

        font-weight: 500;

        height: 40px;
    }

    .main__who_we_are_description p {
        font-size: 7px;

        font-weight: 300;

        line-height: normal;

        width: fit-content;

        height: 100%;
    }

    .carousel {
        width: 179.892px;
        height: 119.5px;

        position: relative;
    }

    .carousel::after {
        content: '';
        position: absolute;

        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        z-index: 1;
    }

    .carousel img {
        width: 179.892px;
        height: 119.5px;

        border-radius: 15px;
    }
}


@media screen and (max-width: 320px) {
    .main__who_we_are {
        height: 226px;

        padding: 0 20px 0 20px;

        background-image: none;

        object-fit: contain;
    }

    .main__who_we_are_description {
        margin: 0;

        width: 100%;

        display: flex;
        align-items: center;
        column-gap: 10px;
    }

    .main__who_we_are_title {
        font-size: 10px;

        font-weight: 500;

        height: 40px;
    }

    .main__who_we_are_description p {
        font-size: 7px;

        font-weight: 300;

        line-height: normal;

        width: fit-content;

        height: 100%;
    }

    .carousel img,
    .carousel {
        width: 159.892px;
        height: 89.5px;

        position: relative;
    }

    .carousel::after {
        content: '';
        position: absolute;

        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        z-index: 1;
    }

    .carousel img {
        border-radius: 15px;
    }
}
