.product__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 80px 100px 10px 100px;
}

.product__title_message {
    color: #023384;
    font-feature-settings: 'liga' off;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 98px; /* 272.222% */
}


@media screen and (max-width: 430px){
    .product__title_message {
        font-size: 8px;
    }

    .product__title {
        padding:0 30px 0 30px;
        height: 56px;
    }

    .product__title img {
        width: 18px;
        height: 18px;
    }
}

