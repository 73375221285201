.products_appetize {
    padding: 65px 0;
    display: flex;
    flex-wrap: wrap;
    column-gap: 63px;
    row-gap: 49px;
    justify-content: center;
    background-image: url('../../files/photos/products/backdrounds/Japanese_Wave_Pattern 1.png');
}

@media screen and (max-width: 430px){
    .products_appetize {
        column-gap: 37px;

        row-gap: 15px;
        background-size: 416px;

        padding: 10px 0;
    }

    .products_appetize img {
        width: 85px;
        object-fit: contain;
    }
}