body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
}

body ul li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: #89a7ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gsc-control-cse  {
  width: 70vw !important;
}

.gsc-input {
  padding: 0 !important;
  height: 25px !important;
}

.gsc-search-button-v2 {
  padding: 0 !important;
}

.gsc-search-button {
  height: 46px !important;
  width: 46px !important;

  padding: 0 !important;
  margin: 0 !important;
}

@media screen and (min-width: 414px) and (max-width: 415px) {
  .gsc-search-button {
    height: 36px !important;
    width: 36px !important;
  }
}

@media screen and (min-width: 374px) and (max-width: 375px) {
  .gsc-search-button {
    height: 36px !important;
    width: 36px !important;
  }
}

@media screen and (max-width: 320px) {
  .gsc-search-button {
    height: 36px !important;
    width: 36px !important;
  }
}