.products__salmons_red {
    display: flex;
    flex-wrap: wrap;
    column-gap: 237px;
    padding: 20px 117px;

    justify-content: center;

    background-image: url('../../files/photos/products/backdrounds/Japanese_Wave_Pattern 1.png');
}

.products__salmons {
    display: flex;
    flex-wrap: wrap;
    column-gap: 69px;

    row-gap: 10px;
    padding: 17px 104px;

    justify-content: center;

    background-image: url('../../files/photos/products/backdrounds/Japanese_Wave_Pattern 1.png');
}

.products__salmons_main:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.products__salmons_main {
    padding-top: 15px;

    transition: transform 0.25s;
}

.products__salmons_main_wrapper {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
}

@media screen and (max-width: 430px){
    .products__salmons, .products__salmons_red {
        column-gap: 37px;

        row-gap: 15px;
        background-size: 416px;

        padding: 10px 0;

        justify-content: center;
        /*height: 205px;*/
    }

    .products__salmons_main_wrapper {
        margin: 0;
    }

    .products__salmons_main_wrapper img {
        width: 280px;
        height: 110px;
    }

    .products__salmons_main {
        padding: 0;
    }
}