.products {
    display: flex;
    flex-wrap: wrap;
    column-gap: 109px;

    gap: 46px;
    justify-content: center;
    background-image: url('../../files/photos/products/backdrounds/Japanese_Wave_Pattern 1.png');

    padding: 24px 0;
}

@media screen and (max-width: 430px){
    .products {
        column-gap: 37px;

        row-gap: 15px;
        background-size: 416px;

        padding: 10px 0;
    }

    .products img {
        width: 85px;
        object-fit: contain;
    }
}