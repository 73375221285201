header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 69px;
    height: 216px;

    position: relative;
    z-index: 999;
    /*background-repeat: no-repeat;*/
    /*background-image: url('../../files/photos/headerBackground/header.png');*/
    /*background-size: cover;*/
}

.header {
    /*background-image: none;*/
    background-color: #DCDCDC;
    box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.10);
}

.header_slide_tree {
    background-color: #F8F7F7;
    box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.10);
}

.header__logo img {
    height: 133px;
    width: 278px;
}

#header__nav_ul {
    display: flex;
    column-gap: 71.5px;
}

#header__nav_ul li {
    font-weight: 600;
    font-size: 28px;
    line-height: 26px;
    /* or 65% */

    position: relative;
    text-align: center;
    letter-spacing: 0.5px;
    color: #656565;
}

.header__nav_ul_li::after {
    content: "";
    height: 27px;
    width: 3px;
    background-color: #ABABAB;
    position: absolute;
    right: -38px;
    border-radius: 3px;
}


.header__nav_ul_li:last-child::after {
    display: none;
}

@media screen and (max-width: 430px){
    .header__logo img {
        height: 31.58px;
        width: 65.83px;
    }

    #header__nav_ul li {
        font-size: 7px;
    }

    #header__nav_ul {
        column-gap: 16px;
    }

    header {
        height: 52px;
        padding: 0 22.5px 0 16.5px;
    }

    .header__nav_ul_li::after {
        height: 7px;
        width: 1px;
        right: -8px;
        top: 8px;
    }

    nav {
        height: 50%;
    }
}