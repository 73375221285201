.products__presentation {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
}

.product__presentation {
    display: flex;
    flex-direction: column;

    align-items: center;

    padding-top: 66px;

    box-sizing: border-box;
    height: 645px;
}

.product__presentation_img {
    width: 166px;
    height: 166px;

    margin-top: 20px;
}

.product__presentation_small_img {
    width: 95px;
    height: 95px;
}

.product__presentation_text {
    display: flex;
    justify-content: center;

    min-height: 234px;
    width: 100%;

    padding: 0 52px 0 52px;

    word-wrap: break-word;
    overflow-wrap: break-word;
    /*overflow: hidden;*/

    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.0025em;
}

.product__presentation_p_white {
    color: #FFFFFF;
}

.product__presentation_p_black {
    color: black;
}

.product__presentation_first {
    background-color: #40649E;
}

.product__presentation_second {
    background-color: #008AD3;
}

.product__presentation_third {
    background-color: #55C2F0;
}

.product__presentation_fourth {
    background-color: #82D6FA;
}

.product__presentation_fiveth {
    background-color: #AEE7FF;
}

.product__presentation_first:hover,
.product__presentation_second:hover,
.product__presentation_third:hover,
product__presentation_fourth:hover,
product__presentation_fiveth:hover{
    opacity: 0.8;
}

@media screen and (min-width: 1500px) and (max-width: 1899px) {
 .product__presentation_text {
     font-size: 34px;
     line-height: 45px;

     padding: 0 32px 0 32px;
 }
}

@media screen and (min-width: 1470px) and (max-width: 1500px) {
    .product__presentation_text {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (min-width: 1282px) and (max-width: 1470px) {
    .product__presentation_text {
        font-size: 28px;
        line-height: 38px;
    }
}

@media screen and (min-width: 1230px) and (max-width: 1282px) {
    .product__presentation_text {
        font-size: 26px;
        line-height: 34px;
    }
}

@media screen and (min-width: 430px) and (max-width: 1230px) {
    .product__presentation_text {
        font-weight: 600;
        font-size: 25px;
        line-height: 34px;

        padding: 0 32px 0 32px;
    }
}

@media screen and (max-width: 430px){
    .product__presentation {
        padding-top: 20px;

        height: 60vh;
    }

    .product__presentation img {
        width: 37px;
        height: 37px;
    }

    .product__presentation_img {
        width: 37px;
        height: 37px;


        padding: 0;
        margin: 0;
    }

    .product__presentation_small_img {
        width: 37px;
        height: 37px;
    }

    .product__presentation_text {
        padding: 0 8px;

        font-size: 9px;
        line-height: 15px;

        min-height: 117px;

        letter-spacing: 0.023px;
    }
}