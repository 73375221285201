footer {
    height: 411px;
    padding: 58px 100px 0 126px;
    background-color: #FFFFFF;
}

.footer__img img {
    width: 192px;
    height: 92px;
}

.footer,
.footer__main,
.footer__main_ul {
    display: flex;
}

.footer {
    justify-content: space-between;
}

.footer__main {
    justify-content: space-around;
    column-gap: 92px;
}

.footer__main_ul {
    flex-direction: column;
    row-gap: 35px;
}

.footer__main_ul_li_text {
    color: #575757;
    left: 38px;

    position: relative;

    color: #575757;

    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px
}

.footer__main_ul_li_text::before {
    content: '';
    position: absolute;
    margin: 30px auto;
    width: 12px;
    height: 12px;
    border-top: 2px solid #6F6F6F;
    border-left: 2px solid #6F6F6F;
    transform: rotate(135deg);

    top: -25px;
    left: -38px;
}

.footer__main_ul_li {
    font-weight: 500;
    font-size: 28px;
    line-height: 28px;
    color: #40649E;
    margin-bottom: 37px;
}

.footer__main_ul_contacts {
    display: flex;
    align-items: center;
    column-gap: 6px;
    height: 48px;

    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: rgba(87, 87, 87, 0.53);
}

.footer__main_ul_contacts p {
    height: 15px;
}

.footer__mobile {
    display: none;
}

@media screen and (min-width: 417px) and (max-width: 1536px){
    .footer__main {
        column-gap: 52px;
    }
}

@media screen and (max-width: 430px){
    .footer {
        display: none;
    }

    footer {
        padding: 0;
        margin: -1px;

        background-color: rgba(193, 188, 188, 1);

        height: auto;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 999;
    }

    .footer__mobile {
        display: block;

        height: 80px;
    }

    .footer__mobile_main {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footer__mobile_main_text {
        color: #40649E;
        text-align: center;
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
    }

    .footer__mobile_main_wrap,
    .footer__mobile_main_wrap a {
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;

        width: 100%;
        height: 80px;
    }

    .footer__mobile_main_wrap p {
        margin: 0;
        padding: 0;
    }

    .footer__mobile_main_wrap svg {
        object-fit: contain;
    }

    .footer__mobile_main_wrap:hover {
        background-color: rgba(94, 84, 84, 0.09);
    }
}
