.sitemap > h3 {
    border-bottom: 1px solid black;
    padding: 20px 0;

    color: #40649E;
}

.sitemap ul {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}


.sitemap ul li {
    color: #575757;

    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}