.salmon__description {
    color: #40649E;
    margin-top: 75px;

    width: 527px;
    height: 144px;

    font-size: 24px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
}

@media screen and (max-width: 430px){
    .salmon__description {
        font-size: 8px;

        margin-top: 15px;

        width: 170px;
        height: 70px;
    }
}
