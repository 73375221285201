.product {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product img {
    width: 364px;
    height: 359px;
    border-radius: 5%;
}

.product:hover img {
    transform: scale(1.1);
    cursor: pointer;
}

.product img {
    transition: transform 0.25s;
}

@media screen and (min-width: 431px) and (max-width: 1536px) {
    .product img {
        width: 344px;
        height: 339px;
    }

    .product {
        height: 409px;
    }
}

@media screen and (max-width: 430px){
    .product img {
        width: 86px;
        height: 85px;
    }
}


/*.product {*/
/*    transition: all 2s ease;*/
/*}*/
/*.product:hover {*/
/*    transition: all 0.1s ease; background-color: #8BE5ED; }*/

/*.product img:hover {*/
/*    cursor: pointer;*/
/*    opacity: 0.7;*/
/*}*/
