.product__description {
   position: absolute;
   bottom: 50px;
   right: 170px;

   background-color: #FFFFFF;
   height: 75px;
   width: 200px;

   border-radius: 10px;
}

.product__description_toko {
   position: absolute;
   bottom: 50px;
   right: 10px;

   background-color: #FFFFFF;
   height: 92px;
   width: 315px;

   border-radius: 10px;
}

.product__description_kg {
   width: inherit;
   height: inherit;
   display: flex;
   align-items: center;
   justify-content: center;

   color: #F49C5D;
   font-weight: 700;
   font-size: 40px;
   line-height: 60px;

   text-align: center;

   opacity: 0.5;
}

.product__description_content {
   font-weight: 500;
   font-size: 20px;
   line-height: 30px;
   /* identical to box height */

   letter-spacing: 0.02em;

   height: 43px;
}

@media screen and  (max-width: 430px){
   .product__description,
   .product__description_toko {
      width: 78.75px;
      height: 27.5px;

      bottom: 10px;
      right: 0;
   }

   .product__description_toko {
      right: 30px;
   }

   .product__description {
      right: 20px;
      bottom: 15px;
   }

   .product__description_kg {
      font-size: 10px;
   }
}