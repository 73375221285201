.description__who_we_are_wrap {
    display: flex;
}

.description__who_we_are {
    background-color: #40649E;
    min-height: 581px;
    width: 100%;

    padding: 76px 116px;
}

.description__who_we_are p {
    color: #FFF;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.075px;
}

.description__who_we_are_img {
    display: none;

    background-color: #40649E;
}

.who_we_are_hr_mobile,
.who_we_are_hr {
    height: 1px;

    background-color: #F6EFEF;

    margin: 47px calc(50% - 188px);
}

.who_we_are_hr {
    width: 376px;
}

.who_we_are_hr_mobile {
    width: 143px;
}

@media screen and (min-width: 321px) and (max-width: 430px) {
    .description__who_we_are_wrap {
        overflow-y: hidden;

        height: 60vh;
    }

    .description__who_we_are p {
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.02px;
    }

    .description__who_we_are {
        min-height: 303px;
        padding: 0 22px;
    }

    .description__who_we_are_img {
        display: block;
        background-image: url('../../../files/photos/who-we-are/Japanese_Wave_Pattern 4 (1).png');
        background-repeat: repeat-y;
        width: 70px;
        height: 100%;
    }

    .who_we_are_hr {
        display: none;
    }

    .who_we_are_hr_mobile {
        display: block;

        margin: 39px calc(50% - 50%);
    }

    .description__who_we_are_second_p {
        margin-top: 40px;
    }
}

@media screen and (max-width: 320px) {
    .description__who_we_are_wrap {
        overflow-y: hidden;

        height: 80vh;
    }

    .description__who_we_are p {
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.02px;
    }

    .description__who_we_are {
        min-height: 303px;
        padding: 0 22px;
    }

    .description__who_we_are_img {
        display: block;
        background-image: url('../../../files/photos/who-we-are/Japanese_Wave_Pattern 4 (1).png');
        background-repeat: repeat-y;
        width: 70px;
        height: 100%;
    }

    .who_we_are_hr {
        display: none;
    }

    .who_we_are_hr_mobile {
        display: block;

        margin: 39px calc(50% - 50%);
    }

    .description__who_we_are_second_p {
        margin-top: 40px;
    }
}