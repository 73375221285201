.footer__search {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.footer__search.show {
    opacity: 1;
}

@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.closeIcon {
    transition: transform 0.3s ease;
}

.closeIcon:hover {
    animation: scaleAnimation 0.5s;
}