.prawn__seafood_page_title {
    background-color: #40649E;
    height: 144px;
    padding: 18px 40px;

    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 50px;
    /* or 208% */

    letter-spacing: 0.0025em;

    color: #FFFFFF;
}

@media screen and (min-width: 351px) and (max-width: 430px){
    .prawn__seafood_page_title {
        height: 29px;

        padding: 3px 20px;

        font-size: 7px;
        line-height: 10px;
    }
}

@media screen and (max-width: 350px) {
    .prawn__seafood_page_title {
        height: 35px;

        padding: 3px 20px;

        font-size: 7px;
        line-height: 10px;
    }
}

