.controller__carousel {
    width: 100%;

    position: relative;
    z-index: 1;
}

.controller__carousel_first_slide,
.controller__carousel_second_slide,
.controller__carousel_third_slide,
.controller__carousel_fourth_slide  {
    position: relative;
    /*top: -3px;*/
}

.controller__carousel_third_slide img {
    max-height: 1300px;
}

/*.controller__carousel_first_slide > img,*/
/*.controller__carousel_second_slide > img,*/
/*.controller__carousel_fourth_slide > img,*/
/*.controller__carousel_third_slide > img {*/
/*    height: 889px;*/
/*}*/

.controller__carousel a span {
    background-color: #ABABAB;
    border-radius: 100%;
    background-size: 50%;

    height: 50px;
    width: 50px;
}

.controller__carousel_first_slide_title,
.controller__carousel_second_slide_title,
.controller__carousel_fourth_slide_title,
.controller__carousel_third_slide_title {
    position: absolute;
}

.controller__carousel_first_slide_title {
    top: 4%;
    right: 10%;
}

.controller__carousel_second_slide_title {
    top: 5.5%;
    left: 40%;
}

.controller__carousel_third_slide_title {
    top: 10%;
    right: 9%;
}

.controller__carousel_fourth_slide_title  {
    top: 10%;
    right: 9%;
}

@media screen and (max-width: 430px){
    .controller__carousel a span {
        display: none;
    }

    .controller__carousel_third_slide_title {
        top: 6%;
        right: 5%;
    }
}