.contacts__form {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    width: 537px;

    margin-bottom: 53px;
    align-items: center;
}

.contacts__form_btn {
    width: 251px;
    height: 53px;
    align-self: center;
}

.contacts__form_btn svg {
    margin-left: 10px;
}

.contacts__form_span {
    color: #0E0E2C;
    font-size: 14px;
    font-weight: 600;

    align-self: start;
}

.contacts__form input,
.contacts__form textarea {
    background-color: white;

    width: 514px;
}

@media screen and (max-width: 430px) {
    .contacts__form {
        width: 300px;

        margin-bottom: 10px;
    }

    .contacts__form input,
    .contacts__form textarea {
        width: 272px;
    }

    .contacts__form_span {
        font-size: 12px;
    }
}