.carousel__arrow {
    display: inline-block;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
}

.carousel__arrow_prev
, .carousel__arrow_next {
    display: flex;
    justify-content: center;
    align-items: center;
}
