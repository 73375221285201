.title__second {
    height: 225px;
    width: 452px;
    background-image: url("../../files/photos/titleSecond/Rectangle 242.png");

    box-sizing: border-box;
    padding: 30px 27px;

    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.title__second__main_text {
    font-weight: 700;
    font-size: 28px;
    line-height: 25px;
    text-transform: uppercase;

    text-align: center;
    letter-spacing: 0.0025em;

    color: #DB6025;
}

.title__second__second_text {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;

    color: #000000;
}

.title__second__second_text_space {
    padding-left: 5px;
}


@media screen and (max-width: 430px) {
    .title__second {
        height: 61px;
        width: 146px;
        padding: 9px 8px;
        row-gap: 0;
        background-image: none;

        border: 1px solid #DC6932;
        border-image: linear-gradient(to bottom, #DC6932, #7A89AE00) 1;
        border-radius: 5px;

        background-origin: border-box;
        background-clip: content-box, border-box;
    }

    .title__second__main_text {
        line-height: 10px;
    }

    .title__second__main_text, .title__second__second_text {
        font-size: 7px;
    }

    .title__second__second_text {
        line-height: 8px;
    }
}