.products__carousel {
    width: 100%;
}

.products__carousel img {
    height: 774px;
    width: 100%;

    object-fit: cover;
    object-position: 50% 50%;
}

.products__carousel a span {
    background-color: #D7D7D7;
    border-radius: 100%;
    background-size: 50%;

    height: 50px;
    width: 50px;
}

@media screen and (max-width: 430px){
    .products__carousel a span {
        display: none;
    }

    .products__carousel img {
        height: 176px;
    }
}
